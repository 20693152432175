html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Director-Regular';
  src: local('Director-Regular'),
    url('./fonts/Director-Regular.otf') format('opentype'),
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  align-items: first baseline;
  /* justify-content: ; */
}

body {
  margin: 0;
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

p.about {
  margin: 0;
  /* text-indent: 0ch; */
  margin-right: 0px;
  margin-bottom: 4%;
  line-height: 1.2em;
}

.linkHighlight {
  color: #ff6600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.css */
.app {
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
  font-family: 'Director-Regular' Droid 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 10px;
  background-color: #f6f6f6;
  align-self: flex-start;
  top: auto;
  letter-spacing: -0.7px;
  justify-content: center;

}

header {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: medium;
  margin-bottom: 50px;
  margin-left: 0;
  margin-top: 45px;
  margin-inline-end: 45%;
  font-size: 28px;
  /* position: sticky;
  top: 10px; */
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: medium;
  text-decoration: none;

}

nav ul li {
  margin: 0 10px;
  text-decoration: none;

}

main {
  display: flex;
  text-decoration: none;

}

button {
  background-color: #ffffff00;
  border: none;
  color: rgb(0, 0, 0);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  flex-direction: column;
  /* position: absolute;
  top: 7%;
  right: 5%; */

}

a {
  text-decoration: none;
  color: #000000;


}

.selected {
  color: #ff6600;
}

.title {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 28px;
  color: #000000;
  text-decoration: underline;
}

.card-title {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  position: relative;
  /* text-align: center; */
  text-decoration: none;
}

.source-link {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  /* text-decoration: none; */
  position: relative;

}

.years {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
  /* text-align: justify; */
}

.date-added {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  /* position: static; */
  /* right: 50%; */
  text-align: right;
  position: relative;
  bottom: 35px;
  text-decoration: none;
}

.container {
  height: 0vh;
  /* set the height of the container */
  /* stroke: #000000;
  stroke-width: 2px; */
}

.main-tags {
  list-style-type: none;
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  flex-direction: column;
  height: 20px;
  text-align: right;
  letter-spacing: -0.7px;
}

.tags {
  position: fixed;
  top: 85px;
  right: 50px;
}

.order-buttons {
  /* position: fixed;
  bottom: 7%;
  left: 3vw; */
  margin-bottom: 2%;
  margin-top: 3%;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  /* gap: 20px; */
  max-width: 100%;
  /* margin-right: 3vw; */
  justify-content: space-between;
  gap: 50px;
  /* position: sticky; */
}

@media only screen and (max-width: 1700px) {
  .image-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 1400px) {
  .image-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}


@media only screen and (max-width: 1100px) {
  .image-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media only screen and (max-width: 800px) {
  .image-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}


.image-card {
  background-color: #ffffff00;
  border: solid;
  border-color: #ffffff70;
  border-width: 0px;
  border-radius: 2px;
  padding-bottom: 50px;
  /* padding-right: 20px; */
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border: #000000;
  /* stroke: #000000; */
  stroke-width: 2px;

}

.image-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 1px;
  margin-bottom: 10px;
  border: 1px solid #000000;

}

.modal {
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  background-color: #fefefe;
  border: 1px solid #000000;
  max-height: 95vh;
  max-width: 33%;
  /* box-shadow: #000000; */
}


@media only screen and (max-width: 900px) {
  .modal {
    min-width: 40vh;
  }
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-image-text {
  margin-bottom: 13%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 2%;
  margin-right: 2%;
}

.modal-image {
  max-height: 700px;
  max-width: 100%;
  margin-bottom: 1%;
}

.modal-title {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
  text-align: center;
  margin-top: 20px;
  margin-bottom: -10px;
}

.modal-text {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-decoration: none;
  text-align: left;
  margin-top: 20px;
  /* margin-left: 2.25%;
  margin-right: 2.25%; */
  /* margin-bottom: 10px; */
}

.modal-years {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-align: left;
  position: absolute;
  bottom: 5.25%;
  left: 5%;
}

.modal-date-added {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-align: right;
  position: absolute;
  bottom: 5.25%;
  right: 5%;
}

.modal-link {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-align: left;
  position: absolute;
  left: 5%;
  bottom: 3.5%;
  font-style: italic;
  text-decoration: none;
}

.modal-close {
  font-family: 'Director-Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 10pt;
  color: #000000;
  text-decoration: underline;
  text-align: center;
  position: absolute;
  bottom: 3.5%;
  right: 5%;
}

.main-content {
  flex: 1;
  display: flex;
  align-items: first baseline;
  flex-direction: column;
}

.submit-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;

}

.submit-categories {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 20px;
}

footer {
  margin-top: 20px;
  color: #a3a3a3;
  font-size: 14px;
  padding-bottom: 50px;
}